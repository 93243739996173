<template>
  <div class="inner panel-padding">
    <section class="article-list-small">
      <div class="article-list-small__header">
        <h2 v-if="articles == null">
          Sorry, there are currently no blog articles available
        </h2>
        <h2 v-if="componentData.fields.articleListHeader && articles != null">
          {{ componentData.fields.articleListHeader }}
        </h2>
      </div>
      <div
        class="article-list-small__inner"
        :class="{
          'article-list-small__three-col':
            componentData.fields.instagramMediaId &&
            componentData.fields.instagramMediaApiToken,
          'article-list-small__two-col': !(
            componentData.fields.instagramMediaId &&
            componentData.fields.instagramMediaApiToken
          ),
        }"
      >
        <div class="article-list-small__articles" v-if="articles != null">
          <h3 class="article-list-small__sub-header">
            {{ componentData.fields.articleListSubHeader }}
          </h3>
          <div class="article-list-small__article-list">
            <div v-for="(article, index) in restOfArticles" :key="index">
              <a
                :href="article.articleLink"
                title="View more"
                class="article-list-small__article"
              >
                <div class="article-list-small__article-date">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="{1.5}"
                    stroke="currentColor"
                    className="size-6"
                    data-v-4cfb636d=""
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                      data-v-4cfb636d=""
                    ></path>
                  </svg>
                  <span>{{
                    publicSiteSettings.fields.globalLocale != "en-US"
                      ? formatDate(article.newsDate)
                      : convertToAmericanDate(article.newsDate.split("T")[0])
                  }}</span>
                </div>
                <h4 class="article-list-small__article-title">
                  {{ article.headerTitle }}
                </h4>
                <i class="icon icon-arrow" aria-hidden="true"></i>
                <p>
                  {{
                    article.blurb.length > 80
                      ? article.blurb.slice(0, 80) + "..."
                      : article.blurb
                  }}
                </p>
              </a>
            </div>
          </div>
          <a
            v-if="componentData.fields.viewAllAnnouncementsLink"
            :href="componentData.fields.viewAllAnnouncementsLink.url"
            class="btn btn--link article-list-small__article-more"
            title="View all announcements"
          >
            <span>{{
              componentData.fields.viewAllAnnouncementsLink.name
            }}</span>
            <div class="icon">
              <SvgIcon name="arrow" />
            </div>
          </a>
        </div>
        <div class="article-list-small__featured" v-if="articles != null">
          <h3 class="article-list-small__featured-title">
            {{ firstArticle.headerTitle }}
          </h3>
          <div
            class="article-list-small__featured-image"
            :style="{ backgroundImage: `url(${firstArticle.headerImage})` }"
          ></div>
          <p
            v-html="firstArticle.blurb"
            class="article-list-small__featured-blurb"
          ></p>
          <a
            :href="firstArticle.articleLink"
            class="btn btn--link article-list-small__featured-more"
            title="Read more"
          >
            <span>Read more</span>
            <div class="icon">
              <SvgIcon name="arrow" />
            </div>
          </a>
        </div>
        <div
          v-if="
            componentData.fields.instagramMediaId &&
            componentData.fields.instagramMediaApiToken
          "
          class="insta-feed"
        >
          <div class="insta-feed">
            <div class="insta-feed__header">
              <SvgIcon name="insta-logo-camera" />
              <SvgIcon name="insta-logo-text" />
            </div>

            <div
              v-if="instagramImages && instagramImages.length"
              class="insta-feed__carousel-wrapper swiper"
              data-insta-feed-carousel
            >
              <div class="swiper-wrapper">
                <a
                  v-for="(image, index) in instagramImages"
                  :key="index"
                  :href="image.permalink"
                  class="insta-feed__carousel-slide swiper-slide"
                  target="_blank"
                >
                  <div
                    class="insta-feed__carousel-image"
                    :style="{ backgroundImage: `url(${image.mediaUrl})` }"
                  >
                    <div class="insta-feed__carousel-header">
                      <div class="insta-feed__likes">
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M31.5 12.375C31.5 8.64708 28.352 5.625 24.4688 5.625C21.5653 5.625 19.0729 7.31442 18 9.72513C16.9271 7.31442 14.4347 5.625 11.5312 5.625C7.648 5.625 4.5 8.64708 4.5 12.375C4.5 23.2058 18 30.375 18 30.375C18 30.375 31.5 23.2058 31.5 12.375Z"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span class="insta-feed__likes-count">{{
                          image.likeCount
                        }}</span>
                      </div>
                      <div class="insta-feed__comments">
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.375 19.139C3.375 21.5406 5.06012 23.6311 7.43619 23.9804C9.03823 24.216 10.6574 24.3988 12.292 24.527C12.9921 24.5819 13.6324 24.9486 14.022 25.533L18 31.5L21.9779 25.5331C22.3675 24.9487 23.0078 24.582 23.708 24.5271C25.3425 24.3989 26.9617 24.2161 28.5637 23.9806C30.9398 23.6313 32.625 21.5408 32.625 19.1392V10.1108C32.625 7.70919 30.9398 5.61874 28.5637 5.2694C25.1161 4.76252 21.5889 4.5 18.0004 4.5C14.4116 4.5 10.8842 4.76257 7.4362 5.26954C5.06013 5.6189 3.375 7.70934 3.375 10.111V19.139Z"
                            stroke="white"
                            stroke-width="2.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span class="insta-feed__comments-count">{{
                          image.commentsCount
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="insta-feed__caption">
                    <p class="insta-caption">
                      {{ truncateCaption(image.caption) }}
                    </p>
                    <div
                      v-if="image.caption && image.caption.length > 300"
                      :href="image.permalink"
                      target="_blank"
                      class="insta-feed__read-more"
                    >
                      <span>Read more</span>
                      <div class="icon">
                        <SvgIcon name="arrow" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <p v-else class="instagram-feed-component__error-message">
              No Instagram images available.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Swiper from "swiper";

import SvgIcon from "@/components/Icon";

export default {
  name: "SmallArticlesList",
  props: ["selectedLanguage", "componentData", "publicSiteSettings"],
  components: {
    SvgIcon,
  },
  data() {
    return {
      articles: [],
      instagramImages: [],
      orderedArticles: [],
      firstArticle: {},
      restOfArticles: [],
      tags: [],
      contentNodePickerUrl:
        this.componentData.fields.contentNodePicker?.system.urlSegment,
    };
  },
  mounted() {
    this.loadArticles();
    this.loadInstagramImages();
  },
  watch: {
    selectedLanguage: "loadArticles",
    instagramImages: {
      handler() {
        this.$nextTick(() => {
          const instaCarousel = document.querySelector(
            "[data-insta-feed-carousel]"
          );
          if (instaCarousel) {
            this.initInstagramCarousel();
          }
        });
      },
    },
  },
  methods: {
    truncateCaption(caption) {
      if (!caption) return "";
      if (caption.length <= 300) return caption;
      return caption.substring(0, 300) + "...";
    },
    loadArticles() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const url =
        this.contentNodePickerUrl != null
          ? "/" + this.contentNodePickerUrl + "/"
          : this.webpage;

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/BlogPostsApi/GetAllArticles?url=${url}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.articles = response.data.articles;
          this.articles =
            Object.keys(this.articles).length === 0 || this.articles.length < 1
              ? null
              : this.articles;
          this.firstArticle = this.articles[0];
          this.restOfArticles =
            this.articles.length >= 6
              ? this.articles.slice(1, 6)
              : this.articles.slice(1);
          this.tags = response.data.blogTags;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadInstagramImages() {
      if (
        this.componentData.fields.instagramMediaId &&
        this.componentData.fields.instagramMediaApiToken
      ) {
        const apiUrl =
          process.env.VUE_APP_REMOTEBASEURL +
          `/api/InstagramFeedApi/RetrieveInstagramFeed?instagramMediaId=${this.componentData.fields.instagramMediaId}&instagramMediaApiToken=${this.componentData.fields.instagramMediaApiToken}&iataCode=${process.env.VUE_APP_IATACODE}`;

        axios
          .get(apiUrl)
          .then((response) => {
            this.instagramImages = response.data;
            this.instagramImages = this.instagramImages.filter(
              (image) => image.mediaUrl.length < 360
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    initInstagramCarousel() {
      const instaCarousel = document.querySelector(
        "[data-insta-feed-carousel]"
      );

      if (instaCarousel) {
        const swiper = new Swiper(instaCarousel, {
          slidesPerView: 1,
          dots: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
        });
      }
    },
    formatDate(date) {
      let newDate = new Date(date);

      let day = String(newDate.getDate()).padStart(2, "0");
      let month = String(newDate.getMonth() + 1).padStart(2, "0");
      let year = newDate.getFullYear();

      return `${day}/${month}/${year}`;
    },
    convertToAmericanDate(dateStr) {
      let [year, month, day] = dateStr.split("-");
      return `${month}/${day}/${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-list-small {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @include breakpoint($tablet) {
      display: grid;
      gap: 4rem;
    }
  }

  &__header {
    display: flex;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__two-col {
    @include breakpoint($tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__three-col {
    @include breakpoint($tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__grid {
    display: flex;
    flex-direction: column-reverse;
    gap: 4rem;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;

    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-column-gap: 2rem;
    }
  }

  &__articles {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__sub-header {
    margin: 0;
  }

  &__article-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-decoration: none;
    border-bottom: 1px solid #f2f2f2;

    &:hover {
      transition: transform 0.2s ease-in-out;
      transform: translateY(-0.5rem);
    }
  }

  &__article-date {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #afafaf;

    span {
      font-size: 1.2rem;
      font-weight: 600;
    }

    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &__article-title {
    margin: 0;
  }

  &__article-more {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;

    @include breakpoint($tablet) {
      margin-top: 2rem;
    }

    .icon {
      transition: transform 0.3s;
    }

    &:hover {
      .icon {
        transform: translateX(0.5rem);
      }
    }
  }

  &__featured {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &:hover {
      .article-list-small__featured-image img {
        transform: scale(1.1);
      }

      .article-list-small__featured-more {
        .icon {
          transform: translateX(0.5rem);
        }
      }
    }
  }

  &__featured-title {
    margin: 0;
  }

  &__featured-image {
    overflow: hidden;
    border-radius: 1rem;
    height: 22rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      border-radius: 1rem;
      transition: transform 0.8s;
    }
  }

  &__featured-blurb {
    margin-bottom: 0;

    @include breakpoint($tablet) {
      margin-bottom: 2rem;
    }
  }

  &__featured-more {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;

    .icon {
      transition: transform 0.3s;
    }
  }
}

.insta-feed {
  overflow: hidden;

  svg path {
    fill: revert-layer;
  }

  video {
    height: 100%;
  }

  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__insta-logo-text {
    margin-left: 0.65rem;
  }

  &__carousel-wrapper {
    position: relative;
  }

  &__carousel {
    overflow: hidden;
  }

  &__carousel-slide {
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    text-decoration: none;
  }

  &__carousel-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 22rem;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
  }

  &__carousel-header {
    padding: 1.5rem !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.25rem;
    position: absolute !important;
    width: calc(100% - 20px);
    top: 10px;
    left: 10px;
    border-radius: 1rem;
    z-index: 1;

    div {
      margin-bottom: 0;
    }
  }

  &__likes,
  &__comments {
    position: relative;
  }

  &__likes-count,
  &__comments-count {
    position: absolute !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    top: -0.75rem;
    right: -0.75rem;
    color: #ffffff;
    font-weight: bold;
  }

  &__likes-count {
    background-color: #f87171;
  }

  &__comments-count {
    background-color: #6ee7b7;
  }

  &__caption {
    padding: 1.25rem 1rem;
    margin-top: 1rem;
  }

  &__carousel-nav {
    position: absolute !important;
    top: 58%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 9999px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 90%;
    backdrop-filter: blur(4px);
  }

  &__carousel-nav-button {
    padding: 0.75rem 1.5rem;
  }

  &__caption {
    padding: 1.25rem 1rem;
  }

  &__read-more {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    &:hover {
      .icon {
        transform: translateX(5px);
      }
    }

    span {
      font-size: 1.8rem;
      font-weight: 600;
    }

    .icon {
      transition: transform 0.3s ease-in-out;
    }
  }

  .slick-dots {
    margin: 0 auto;
    right: auto;
    position: relative;

    li button {
      background: #dedede;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 5px;
    }

    li.slick-active button {
      background: #00a8e1;
    }
  }
}
</style>
