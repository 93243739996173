<template>
    <section class="inner">
        <div class="parking-component">
            <div class="parking-content">
                <h2> {{ componentData.fields.title }} </h2>
                <div class="rich-text-block" v-html="componentData.fields.description"></div>
            </div>
    
        <div class="parking-table">
            <table>
            <tr>
                <th class="content__timetable--column-dep">Parking Location</th>
                <th class="content__timetable--column-dep">Daily Rates</th>
                <th class="content__timetable--column-direction">Lot Status</th>
            </tr>
            <tr
                v-for="(carPark, index) in componentData.fields.parkingRatesTable"
                :key="index"
            >
                <td>{{ carPark.fields.parkingLocationName }}</td>
                <td>${{ carPark.fields.dailyRate }}</td>
                <td
                :id="`carpark-status-${addHyphensIfSpaces(carPark.fields.parkingLocationName)}`"
                :class="getStatus(carPark.fields)"
                >
                {{ getStatus(carPark.fields) }}
                </td> 
            </tr>
            </table>
                <div class="rich-text-block" v-html="componentData.fields.extraInfoCopy"></div>
        </div>
        </div>


    </section>
  </template>
  
  <script>
export default {
  name: "ParkingAvailabilityComponent",
  props: ["componentData"],
  methods: {
    getStatus(carParkInfo) {
      this.$nextTick(() => {
        const statusElement = document.getElementById(`carpark-status-${this.addHyphensIfSpaces(carParkInfo.parkingLocationName)}`);
        if (!statusElement) return;

        if (carParkInfo.lotStatus.includes("Available")) {
        statusElement.classList.add("status-green");
        statusElement.textContent = "Available";
      } else if (carParkInfo.lotStatus.includes("Closed")) {
        statusElement.classList.add("status-red");
        statusElement.textContent = "Closed";
      } else if (carParkInfo.lotStatus.includes("Partially Full")) {
        statusElement.classList.add("status-orange");
        statusElement.textContent = "Almost Full";
      } else if (carParkInfo.lotStatus.includes("Full")) {
        statusElement.classList.add("status-red");
        statusElement.textContent = "Full";
      }
      });
    },
    addHyphensIfSpaces(str) {
      if (str.includes(" ")) {
        return str.split(" ").join("-");
      }
      return str;
    },
  },
};
</script>
  
<style lang="scss">

.parking-component {
    display: flex;
    flex-direction: column;

    @include breakpoint($desktop-sml) {
        flex-direction: row;
    }
    margin: 4rem 0 4rem 0;

    .parking-content {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
        margin-bottom: 3rem;
        @include breakpoint($desktop-sml) {
          margin-bottom: 0;
          max-width: 40rem;
          margin-right: 30px;
      }
    }
    
    .parking-table {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include breakpoint($desktop-sml) {
          margin-right: 1rem;
          margin-top: 7rem;
      }

        table {
            width: 100%;
            border-collapse: collapse;
            font-size: 14px;
            text-align: center; 
            margin-bottom: 2rem;

            th, td {
                padding: 12px 15px;
                border: 1px solid #ddd;
                text-align: center; 
            }

            th {
                background-color: #f4f4f4;
                font-weight: bold;
            }

            tr:nth-child(even) {
                background-color: #f9f9f9;
            }

            tr:hover {
                background-color: #f1f1f1;
            }

            td {
                vertical-align: middle;
            }

            @include breakpoint($desktop-sml) {
                font-size: 16px;

                th, td {
                padding: 10px 8px;
                }
            }
        }
    }
}

.status-green {
  color: green;
}
.status-red {
  color: red;
}
.status-orange {
  color: orange;
}
</style>
  